import { AppBar, Toolbar } from '@mui/material'
import { MainButton } from './application-bar/MainButton'
import { Title } from './application-bar/Title'
import { Telegram } from './application-bar/Telegram'
import { state } from '../state'
import type { ReactNode } from 'react'

export const ApplicationBar = (): ReactNode =>
  <AppBar position='fixed'>
    <Toolbar>
      <MainButton screen={state.screen}/>
      <Title screen={state.screen}/>
      <Telegram screen={state.screen}/>
    </Toolbar>
  </AppBar>
