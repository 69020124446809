import { observer } from 'mobx-react-lite'
import { Typography } from '@mui/material'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { keys } from '../../i18n/keys'
import { type Screen } from '../../state/screen'

export const Title = observer<{ screen: Screen }>(
  ({ screen }) => {
    useTranslation()
    const title = ((): string => {
      switch (screen.value) {
        case 'main': return t(keys.bar.title.main)
        default: return t(keys.bar.title.screenNotFound)
      }
    })()
    return <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>{title}</Typography>
  })
