import { action, observable } from 'mobx'
import { isString } from '../utils/checkers'

export const DEFAULT = ''

export type Struct = {
  value: string
}

export class Url {
  @observable accessor value: string = DEFAULT

  @action setValue (value: string): void {
    this.value = value
  }

  w = (struct: Record<string, any>): void => {
    if (isString(struct.value))
      this.setValue(struct.value)
  }

  r = (): Struct => ({ value: this.value })
}
