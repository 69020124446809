import { observer } from 'mobx-react-lite'
import { type Language as LanguageState } from '../state/language'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { languages } from '../i18n'
import { keys } from '../i18n/keys'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { type View } from '../state/view'
import { closeLanguageDialog } from '../actions/languageDialog'
import { setLanguage } from '../actions/language'
import { type ChangeEvent } from 'react'

export const LanguageDialog = observer<{ language: LanguageState, view: View }>(
  ({ language, view }) => {
    useTranslation()

    const onRadioGroupChange = (e: ChangeEvent<HTMLInputElement>): void => {
      closeLanguageDialog()
      setLanguage(e.target.value)
    }

    return <Dialog open={view.visible} onClose={closeLanguageDialog}>
      <DialogTitle sx={{ p: 2 }}>{t(keys.languageDialog.title)}</DialogTitle>
      <FormControl sx={{ pl: 0.5, pr: 0.5, minWidth: 256 }}>
        <RadioGroup
          value={language.value}
          onChange={onRadioGroupChange}
        >
          { languages.map((value) =>
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio />}
              label={t(keys.language, { lng: value })}
              checked={language.value === value}
              disabled={language.value === value}
              sx={{ m: 0 }}
            />
          )}
        </RadioGroup>
      </FormControl>
      <DialogActions>
        <Button autoFocus onClick={closeLanguageDialog}>{t(keys.languageDialog.button)}</Button>
      </DialogActions>
    </Dialog>
  })
