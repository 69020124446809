import { Theme, type Struct as ThemeStruct } from './theme'
import { Screen, type Struct as ScreenStruct } from './screen'
import { View, type Struct as ViewStruct } from './view'
import { Language, type Struct as LanguageStruct } from './language'
import { Tmp } from './tmp'
import { Scroll, type Struct as MainStruct } from './scroll'

type Struct = {
  theme: ThemeStruct
  screen: ScreenStruct
  drawer: ViewStruct
  languageDialog: ViewStruct
  aboutDialog: ViewStruct
  telegramMenu: ViewStruct
  language: LanguageStruct
  mainScroll: MainStruct
}

class State {
  theme = new Theme()
  screen = new Screen()
  drawer = new View()
  languageDialog = new View()
  aboutDialog = new View()
  telegramMenu = new View()
  language = new Language()
  mainScroll = new Scroll()

  tmp = new Tmp()

  read = (): Struct => ({
    theme: this.theme.read(),
    screen: this.screen.read(),
    drawer: this.drawer.read(),
    languageDialog: this.languageDialog.read(),
    aboutDialog: this.aboutDialog.read(),
    telegramMenu: this.telegramMenu.read(),
    language: this.language.read(),
    mainScroll: this.mainScroll.read()
  })

  write (data?: any): void {
    if (data === undefined)
      return

    this.theme.write(data.theme)
    this.screen.write(data.screen)
    this.drawer.write(data.drawer)
    this.languageDialog.write(data.languageDialog)
    this.aboutDialog.write(data.aboutDialog)
    this.telegramMenu.write(data.telegramMenu)
    this.language.write(data.language)
    this.mainScroll.write(data.mainScroll)
  }
}

export const state = new State()
