import { Box, useTheme } from '@mui/material'
import { state } from '../state'
import { Main } from './Main'
import { ScreenNotFound } from './ScreenNotFound'
import type { ReactNode } from 'react'

export const Content = (): ReactNode => {
  const { mixins: { toolbar } } = useTheme()
  return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', minHeight: '100%' }}>
    <Box sx={ { flexGrow: 0, ...toolbar }}></Box>
    <Box sx={{ flexGrow: 1, height: 0 }}>
      <Main screen={state.screen}/>
      <ScreenNotFound screen={state.screen}/>
    </Box>
  </Box>
}
