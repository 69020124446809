import { state } from '../state'
import { reaction } from 'mobx'

const TIMEOUT = 100

export function autoUpdateMainScroll (): void {
  let timeout: NodeJS.Timeout | undefined
  reaction(() => state.tmp.mainScroll.position, (value) => {
    if (timeout !== undefined)
      clearTimeout(timeout)
    timeout = setTimeout(() => { state.mainScroll.setPosition(value) }, TIMEOUT)
  })
}
