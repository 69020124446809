import { observer } from 'mobx-react-lite'
import { type View } from '../../../state/view'
import { Menu as MenuMaterial, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { keys } from '../../../i18n/keys'
import { faBullhorn, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { closeTelegramMenu } from '../../../actions/telegramMenu'
import { type Anchor } from '../../../state/anchor'
import { ID } from '../../../index'
import { WIDTH } from '../common/icon'
import { getTelegramLink } from '../../../utils/telegram'
import { config } from '../../../config'

export const Menu = observer<{ view: View, anchor: Anchor }>(
  ({ view, anchor }) => {
    useTranslation()
    return <MenuMaterial
      anchorEl={anchor.element ?? document.getElementById(ID)}
      open={view.visible}
      onClose={closeTelegramMenu}
    >
      <MenuItem component='a' href={getTelegramLink(config.telegram.group)} target='_blank' onClick={closeTelegramMenu}>
        <span style={{ width: WIDTH }}><FontAwesomeIcon icon={faUserGroup} /></span>
        {t(keys.bar.telegramMenu.group)}
      </MenuItem>
      <MenuItem component='a' href={getTelegramLink(config.telegram.channel)} target='_blank' onClick={closeTelegramMenu}>
        <span style={{ width: WIDTH }}><FontAwesomeIcon icon={faBullhorn} /></span>
        {t(keys.bar.telegramMenu.channel)}
      </MenuItem>
    </MenuMaterial>
  })
