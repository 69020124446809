import { Box, Button } from '@mui/material'
import { t } from 'i18next'
import { keys } from '../i18n/keys'
import { setScreen } from '../actions/screen'
import { useTranslation } from 'react-i18next'
import { type ReactElement } from 'react'
import pepe from './assets/pepe-dancing.gif'

export const NotFound = (): ReactElement => {
  useTranslation()

  const setMainScreen = (): void => { setScreen('main') }

  return <Box sx={{
    width: '100%',
    height: '100%',
    lineHeight: 0
  }}>
    <Box sx={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img style={{ width: '50vmin' }} src={pepe} alt={t(keys.notFound.alt)}/>
      <Button variant='contained' size='large' onClick={setMainScreen}>{t(keys.notFound.button)}</Button>
    </Box>
  </Box>
}
