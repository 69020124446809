import { observer } from 'mobx-react-lite'
import { type Screen } from '../state/screen'
import { NotFound } from './NotFound'

export const ScreenNotFound = observer<{ screen: Screen }>(
  ({ screen }) => {
    return screen.isUnknown()
      ? <NotFound/>
      : null
  })
