import { state } from '../state'
import { writeDialogFromParamToState } from './common/dialog'

const GET_PARAMETER = 'tgWebAppStartParam'

/**
 * Read tgWebAppStartParam GET variable
 * @see https://core.telegram.org/bots/webapps#direct-link-mini-apps
 *
 * Format:
 * param1=value1=param2=value2...
 */
export function writeTelegramWebAppUrlParamToState (): void {
  const startup = (new URL(location.toString())).searchParams.get(GET_PARAMETER)
  if (startup === null)
    return

  const parts = startup.split('=')
  if (parts.length % 2 !== 0)
    return

  const parameters: Record<string, string> = {}
  for (let i = 0; i < parts.length; i += 2)
    parameters[parts[i]] = parts[i + 1]

  if (parameters.screen !== undefined)
    state.screen.setValue(parameters.screen)

  if (parameters.dialog !== undefined)
    writeDialogFromParamToState(parameters.dialog)
}
