import { observer } from 'mobx-react-lite'
import { IconButton } from '@mui/material'
import { type View } from '../../../state/view'
import { openTelegramMenu } from '../../../actions/telegramMenu'
import { type ForwardedRef, forwardRef } from 'react'
import { type ExtendButtonBase } from '@mui/material/ButtonBase'
import { type IconButtonTypeMap } from '@mui/material/IconButton/IconButton'
import telegram from '../assets/telegram.svg'
import { BUTTON_SIZE } from '../common/icon'

export const Icon = observer<{ view: View, ref: any }>(
  forwardRef<ExtendButtonBase<IconButtonTypeMap>, { view: View }>(
    function Icon ({ view }, ref: ForwardedRef<any>) {
      return <IconButton
        size='large'
        edge='start'
        color='inherit'
        sx={{ mr: 0, width: BUTTON_SIZE, height: BUTTON_SIZE }}

        aria-haspopup='true'
        aria-expanded={view.visible ? 'true' : undefined}
        onClick={openTelegramMenu}

        ref={ref}
      >
        <img src={telegram} alt='Telegram'/>
      </IconButton>
    }
  ))
