import { type ReactNode, useEffect, useRef } from 'react'
import { Icon } from './telegram/Icon'
import { Menu } from './telegram/Menu'
import { state } from '../../state'
import { setTelegramMenuAnchor } from '../../actions/telegramMenu'

export const Telegram = (): ReactNode => {
  useEffect((): void => { setTelegramMenuAnchor(ref.current) })
  const ref = useRef<HTMLElement>(null)
  return <div>
    <Icon view={state.telegramMenu} ref={ref} />
    <Menu view={state.telegramMenu} anchor={state.tmp.telegramMenuAnchor} />
  </div>
}
