import { observer } from 'mobx-react-lite'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { keys } from '../i18n/keys'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { type View } from '../state/view'
import { closeAboutDialog } from '../actions/aboutDialog'
import { getTelegramLink, getTelegramTag } from '../utils/telegram'
import { type Theme } from '../state/theme'
import packageJson from './../../package.json'
import { config } from '../config'

export const AboutDialog = observer<{ view: View, theme: Theme }>(
  ({ theme, view }) => {
    useTranslation()

    const textColor = { color: theme.value === 'light' ? '#000000DD' : '#FFF' }
    const linkStyle = {
      textDecoration: 'none',
      color: theme.value === 'light' ? '#1976d2' : '#90caf9'
    }

    return <Dialog open={view.visible} onClose={closeAboutDialog}>
      <DialogTitle>
        {t(keys.aboutDialog.title)}<br/>
        <Box sx={{ p: 0, lineHeight: 1.5, fontSize: '14px', opacity: 0.5 }}>{t(keys.aboutDialog.version)} {packageJson.version}</Box>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 256 }}>
        <DialogContentText sx={{ ...textColor }}>{t(keys.aboutDialog.features)}{' '}
          <a target='_blank' style={linkStyle} href={getTelegramLink(config.telegram.bot)}>
            {getTelegramTag(config.telegram.bot)}
          </a>
        </DialogContentText>
        <DialogContentText sx={{ pt: 2, ...textColor }}>
          {t(keys.aboutDialog.questions)}{' '}
          <a target='_blank' style={linkStyle} href={getTelegramLink(config.telegram.group)} rel="noreferrer">
            {t(keys.aboutDialog.group)}
          </a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeAboutDialog}>{t(keys.aboutDialog.button)}</Button>
      </DialogActions>
    </Dialog>
  })
