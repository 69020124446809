export type V = {
  version?: string
}

export abstract class Versioned<Struct> {
  version?: string

  write = (struct?: any): void => {
    if (struct !== undefined && struct.version === this.version)
      this.w(struct)
  }

  read = (): Struct & V => ({ version: this.version, ...this.r() })

  protected abstract w (struct: any): void
  protected abstract r (): Struct
}
