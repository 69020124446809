import { action, observable } from 'mobx'
import { Versioned } from './common/version'
import { isString } from './utils/checkers'

export const LIGHT = 'light'
export const DARK = 'dark'
export const paletteModes = [LIGHT, DARK] as const
export type PaletteMode = typeof paletteModes[number]

export type Struct = {
  value: PaletteMode
}

export class Theme extends Versioned<Struct> {
  @observable accessor value: PaletteMode = LIGHT

  @action setValue (value: string): void {
    if (value === LIGHT || value === DARK)
      this.value = value
  }

  w = (struct: Record<string, any>): void => {
    if (isString(struct.value))
      this.setValue(struct.value)
  }

  r = (): Struct => ({ value: this.value })
}
