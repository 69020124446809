import { observer } from 'mobx-react-lite'
import { type ReactNode } from 'react'
import {
  Box,
  Divider,
  Drawer as DrawerMaterial,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion, faLanguage, faMoon } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { keys } from '../i18n/keys'
import { t } from 'i18next'
import { type View } from '../state/view'
import { Switcher } from './drawer/Switcher'
import { state } from '../state'
import { openAboutDialog } from '../actions/aboutDialog'
import { closeDrawer } from '../actions/drawer'
import { switchTheme } from '../actions/theme'
import { openLanguageDialog } from '../actions/languageDialog'

const ListItemIconFixedWidth = (props: { children: ReactNode }): ReactNode =>
  <ListItemIcon sx={{ minWidth: 32 }}>
    {props.children}
  </ListItemIcon>

export const Drawer = observer<{ drawer: View }>(
  ({ drawer }) => {
    useTranslation()

    return <DrawerMaterial open={drawer.visible} onClose={closeDrawer}>
      <Box sx={{ width: 256 }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={openAboutDialog}>
              <ListItemIconFixedWidth>
                <FontAwesomeIcon icon={faCircleQuestion} />
              </ListItemIconFixedWidth>
              <ListItemText primary={t(keys.drawer.about)}/>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={openLanguageDialog}>
              <ListItemIconFixedWidth>
                <FontAwesomeIcon icon={faLanguage} />
              </ListItemIconFixedWidth>
              <ListItemText primary={t(keys.drawer.language)} />
              <Box sx={{ opacity: 0.5 }}>{t(keys.language)}</Box>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={switchTheme}>
              <ListItemIconFixedWidth>
                <FontAwesomeIcon icon={faMoon} />
              </ListItemIconFixedWidth>
              <ListItemText primary={t(keys.drawer.nightMode)} />
              <Switcher theme={state.theme} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </DrawerMaterial>
  })
