import { autorun } from 'mobx'
import { state } from '../state'

export function autoWriteStateToWindowLocation (): void {
  const isFirstRun = true
  autorun(async () => {
    const data = { screen: state.screen.value }
    const url = `?${new URLSearchParams(data).toString()}`
    if (isFirstRun)
      window.history.replaceState(data, '', url)
    else
      window.history.pushState(data, '', url)
  })
}
