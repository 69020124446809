import { action, observable } from 'mobx'
import { Versioned } from './common/version'
import { EN, type Language as LanguageCode, languages } from '../i18n'
import { isString } from './utils/checkers'

export type Struct = {
  value: LanguageCode
}

const isLanguageCode = (value: string): value is LanguageCode => languages.includes(value as LanguageCode)

export class Language extends Versioned<Struct> {
  @observable accessor value: LanguageCode = EN

  @action setValue (value: string): void {
    this.value = isLanguageCode(value) ? value : EN
  }

  w = (struct: Record<string, any>): void => {
    if (isString(struct.value))
      this.setValue(struct.value)
  }

  r = (): Struct => ({ value: this.value })
}
