import { action, observable } from 'mobx'
import { Versioned } from './common/version'
import { isString } from './utils/checkers'

export const screens = ['main'] as const
export type ScreenValue = typeof screens[number]

export const DEFAULT = 'main'

export type Struct = {
  value: string
}

export class Screen extends Versioned<Struct> {
  @observable accessor value: ScreenValue | string = DEFAULT

  @action setValue (value: ScreenValue | string): void {
    this.value = value
  }

  isUnknown (): boolean {
    return !screens.includes(this.value as ScreenValue)
  }

  w = (struct: Record<string, any>): void => {
    if (isString(struct.value))
      this.setValue(struct.value)
  }

  r = (): Struct => ({ value: this.value })
}
