import { action, observable } from 'mobx'
import { Versioned } from './common/version'
import { isBoolean } from './utils/checkers'

export type Struct = {
  visible: boolean
}

export class View extends Versioned<Struct> {
  @observable accessor visible = false

  @action setVisible (value: boolean): void {
    this.visible = value
  }

  w = (struct: Record<string, any>): void => {
    if (isBoolean(struct.visible))
      this.setVisible(struct.visible)
  }

  r = (): Struct => ({ visible: this.visible })
}
