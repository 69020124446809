import './index.sass'
import { initI18next } from './actions/i18next'
import { writeI18nextToState } from './actions/language'
import { writeLocalStorageToState } from './actions/localStorage'
import { writeWindowLocationToUrl } from './actions/url'
import { writeTelegramWebAppUrlParamToState } from './actions/telegramWebApp'
import { copyMainScrollToTmp } from './actions/mainScroll'
import { autoUpdateI18nextLanguage } from './auto/autoUpdateI18nextLanguage'
import { autoWriteStateToLocalStorage } from './auto/autoWriteStateToLocalStorage'
import { autoWriteStateToWindowLocation } from './auto/autoWriteStateToWindowLocation'
import { autoWriteUrlParametersToState } from './auto/autoWriteUrlParametersToState'
import { autoUpdateMainScroll } from './auto/autoUpdateMainScroll'
import { router } from './router'
import { createRoot } from 'react-dom/client'
import { App } from './components/App'
import { state } from './state'

export const ID = 'root'

async function main (): Promise<void> {
  await initI18next()

  writeI18nextToState()
  writeLocalStorageToState()
  writeWindowLocationToUrl()
  writeTelegramWebAppUrlParamToState()
  copyMainScrollToTmp()

  autoUpdateI18nextLanguage()
  autoWriteStateToLocalStorage()
  autoWriteStateToWindowLocation()
  autoWriteUrlParametersToState()
  autoUpdateMainScroll()

  router()

  const container = document.getElementById(ID)
  if (container !== null) createRoot(container).render(<App theme={state.theme} />)
}

main().catch(console.error)
