import { type InitOptions } from 'i18next'

export const EN = 'en'
export const RU = 'ru'
export const languages = [EN, RU] as const
export type Language = typeof languages[number]

export type Translation = {
  language: string
  bar: {
    title: {
      main: string
      screenNotFound: string
    }
    telegramMenu: {
      group: string
      channel: string
    }
  }
  drawer: {
    about: string
    language: string
    nightMode: string
  }
  aboutDialog: {
    title: string
    version: string
    features: string
    questions: string
    group: string
    button: string
  }
  languageDialog: {
    title: string
    button: string
  }
  notFound: {
    alt: string
    button: string
  }
  main: {
    soon: string
  }
}

export const initOptions: InitOptions & { resources: Record<Language, { translation: Translation }> } = {
  fallbackLng: EN,
  resources: {
    en: {
      translation: {
        language: 'English',
        bar: {
          title: {
            main: 'Word of Mouth',
            screenNotFound: 'Screen not found'
          },
          telegramMenu: {
            group: 'Group',
            channel: 'Channel'
          }
        },
        drawer: {
          about: 'About',
          language: 'Language',
          nightMode: 'Night mode'
        },
        aboutDialog: {
          title: 'Word of Mouth',
          version: 'version',
          features: 'Web applications for',
          questions: 'Ask questions in',
          group: 'group',
          button: 'OK'
        },
        languageDialog: {
          title: 'Language',
          button: 'OK'
        },
        notFound: {
          alt: 'Not found',
          button: 'Back to main screen'
        },
        main: {
          soon: 'Soon'
        }
      }
    },
    ru: {
      translation: {
        language: 'Русский',
        bar: {
          title: {
            main: 'Word of Mouth',
            screenNotFound: 'Экран не найден'
          },
          telegramMenu: {
            group: 'Группа',
            channel: 'Канал'
          }
        },
        drawer: {
          about: 'О приложении',
          language: 'Язык',
          nightMode: 'Ночной режим'
        },
        aboutDialog: {
          title: 'Word of Mouth',
          version: 'версия',
          features: 'Веб приложения для',
          questions: 'Задавайте вопросы в',
          group: 'группу',
          button: 'OK'
        },
        languageDialog: {
          title: 'Язык',
          button: 'ОК'
        },
        notFound: {
          alt: 'Не найдено',
          button: 'Вернуться на главный экран'
        },
        main: {
          soon: 'Скоро'
        }
      }
    }
  }
}
