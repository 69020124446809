import { observer } from 'mobx-react-lite'
import { type Screen } from '../../state/screen'
import { openDrawer } from '../../actions/drawer'
import { setScreen } from '../../actions/screen'
import { IconButton, useMediaQuery } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const WIDTH = 600
const ICON_SIZE = '20px'

export const MainButton = observer<{ screen: Screen }>(
  ({ screen }) => {
    const isBig = useMediaQuery(`(min-width: ${WIDTH}px)`)

    const onClick = (): void => {
      if (screen.value === 'main')
        openDrawer()
      else
        setScreen('main')
    }

    return <IconButton
      size='large'
      edge='start'
      color='inherit'
      sx={{ mr: isBig ? 1.5 : 0.5 }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={ screen.value === 'main' ? faBars : faArrowLeft } fontSize={ICON_SIZE} />
    </IconButton>
  })
