import { action, observable } from 'mobx'

export type AnchorElement = HTMLElement | null

export class Anchor {
  @observable accessor element: AnchorElement | undefined

  @action setElement (value?: AnchorElement): void {
    this.element = value
  }
}
