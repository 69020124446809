import { Versioned } from './common/version'
import { action, observable } from 'mobx'
import { isNumber } from './utils/checkers'

const DEFAULT_POSITION = 0

export type Struct = {
  position: number
}

export class Scroll extends Versioned<Struct> {
  @observable accessor position: number = DEFAULT_POSITION

  @action setPosition (value: number): void {
    this.position = value
  }

  w = (struct: Record<string, any>): void => {
    if (isNumber(struct.position))
      this.setPosition(struct.position)
  }

  r = (): Struct => ({ position: this.position })
}
