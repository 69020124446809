import { state } from '../state'
import i18next from 'i18next'

export function setLanguage (value: string): void {
  state.language.setValue(value)
}

export function writeI18nextToState (): void {
  state.language.setValue(i18next.language)
}
