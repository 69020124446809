import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { initOptions } from '../i18n'

export async function initI18next (): Promise<void> {
  await i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(initOptions)
}
