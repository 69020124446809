import { observer } from 'mobx-react-lite'
import { Box, Typography } from '@mui/material'
import { type Screen } from '../state/screen'
import { state } from '../state'
import { useEffect } from 'react'
import { setMainScroll } from '../actions/mainScroll'
import { t } from 'i18next'
import { keys } from '../i18n/keys'

export const Main = observer<{ screen: Screen }>(
  ({ screen }) => {
    const visible = screen.value === 'main'

    const onScroll = (): void => {
      if (visible)
        setMainScroll(scrollY)
    }

    useEffect(() => {
      scroll(0, state.tmp.mainScroll.position)
      addEventListener('scroll', onScroll)
      return () => { removeEventListener('scroll', onScroll) }
    })

    return (visible)
      ? <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Typography variant='h2'>
              { t(keys.main.soon).toUpperCase() }
            </Typography>
          </Box>
        </Box>
      : null
  })
