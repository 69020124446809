export const config = {
  telegram: {
    // Telegram public group name without '@' e.g. 'mygroup'
    group: '<telegram.group>',

    // Telegram channel name without '@' e.g. 'mychannel'
    channel: '<telegram.channel>',

    // Telegram bot name without '@' e.g. 'mybot'
    bot: '<telegram.bot>',

    // Telegram bot webapp e.g. 'app'
    app: '<telegram.app>'
  }
}

