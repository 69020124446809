import { state } from '../state'

const KEY = 'state'

export function writeLocalStorageToState (): void {
  state.write(JSON.parse(localStorage.getItem(KEY) ?? '{}'))
}

export function writeStateToLocalStorage (): void {
  localStorage.setItem(KEY, JSON.stringify(state.read()))
}
