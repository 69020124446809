import { state } from '../state'
import type { AnchorElement } from '../state/anchor'

export function openTelegramMenu (): void {
  state.telegramMenu.setVisible(true)
}

export function closeTelegramMenu (): void {
  state.telegramMenu.setVisible(false)
}

export function setTelegramMenuAnchor (element?: AnchorElement): void {
  state.tmp.telegramMenuAnchor.setElement(element)
}
