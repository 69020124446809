import { state } from '../state'
import { autorun } from 'mobx'
import { DEFAULT as DEFAULT_SCREEN } from '../state/screen'
import { writeDialogFromParamToState } from '../actions/common/dialog'

export function autoWriteUrlParametersToState (): void {
  const isFirstRun = true
  autorun(async () => {
    const url = new URL(state.tmp.url.value)
    const screen = url.searchParams.get('screen')
    const dialog = url.searchParams.get('dialog')

    if (isFirstRun && screen !== null)
      state.screen.setValue(screen)
    else
      state.screen.setValue(screen ?? DEFAULT_SCREEN)

    if (dialog !== null)
      writeDialogFromParamToState(dialog)
  })
}
