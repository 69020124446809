import { observer } from 'mobx-react-lite'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import { type Theme } from '../state/theme'
import { ApplicationBar } from './ApplicationBar'
import { state } from '../state'
import { Drawer } from './Drawer'
import { LanguageDialog } from './LanguageDialog'
import { AboutDialog } from './AboutDialog'
import { Content } from './Content'

export const App = observer<{ theme: Theme }>(
  ({ theme }) => {
    const newTheme = createTheme({ palette: { mode: theme.value } })
    return <ThemeProvider theme={newTheme}>
      <CssBaseline/>
      <ApplicationBar/>
      <Content/>
      <Drawer drawer={state.drawer}/>
      <AboutDialog view={state.aboutDialog} theme={state.theme}/>
      <LanguageDialog language={state.language} view={state.languageDialog}/>
    </ThemeProvider>
  })
