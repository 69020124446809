import { initOptions, Translation } from './index'

type StringTree = { [key: string]: StringTree | string }

/**
 * Save object variable paths to object variables
 * Required to generate object that help to call `t()` method from i18next library
 * @param translation Example
 *   {
 *     kek: {
 *       pek: {
 *         a: 'A'
 *         b: 'B'
 *       }
 *     }
 *   }
 * @param prefix Example
 *   ``
 * @return {StringTree} Example:
 *  {
 *     kek: {
 *       pek: {
 *         a: 'kek.pek.a'
 *         b: 'kek.pek.b'
 *       }
 *     }
 *   }
 */
function readKeys <T> (translation: StringTree, prefix = ''): T {
  const result: StringTree = {}
  const generatePrefix = (key: string): string => prefix === '' ? key : `${prefix}.${key}`
  const isString = (translation: StringTree | string): translation is string => typeof translation === 'string'
  for (const [key, value] of Object.entries(translation))
    result[key] = isString(value)
      ? generatePrefix(key)
      : readKeys(value, generatePrefix(key))
  return result as T
}

export const keys = readKeys<Translation>(initOptions.resources.en.translation)
